import { baseApiUrl } from "./config.js"
// import { loginIn } from "./login.js"
import { clearStorage, getTokenStorage, getJsessionId, setJsessionId } from "../service/storage.js"
import {debounceEvent} from "./index.js"
class Request {
    constructor(parms) {
        this.withBaseURL = parms.withBaseURL
        this.baseURL = parms.baseURL
    }
    get(url, data) {
        return this.request('GET', url, data)
    }
    post(url, data) {
        return this.request('POST', url, data)
    }
    put(url, data) {
        return this.request('PUT', url, data)
    }
    async request(method, url, data) {
        const vm = this
        try {
			
            const token = getTokenStorage() || ''
            const [error, res] = await uni.request({
                url: vm.withBaseURL ? vm.baseURL + url : url,
                data,
                method,
                header: {
                    // 'content-type': 'application/x-www-form-urlencoded',
                	//#ifdef H5
                	deviceType: 'H5',
                	//#endif
                	//#ifdef APP-PLUS
                	deviceType: 'APP',
                	//#endif
                    Authorization: getTokenStorage() || ''
                }
            })
            // if (res && res.header['Set-Cookie']) {
            //     const cookie = res.header['Set-Cookie']
            //     setJsessionId(cookie.split(';')[0] || '')
            // }
            if (!error && (res.statusCode === 200 || res.statusCode === 204 || res.statusCode === 201)) {
                if(res.data.code === 10013 || res.data.code === 10010) {
                	toLogin()
                	return [null, res.data]
                } else {
                    if (res.data.code == 11111) uni.showModal({
                        content: '请前往绑定手机号码',
                         showCancel: false,
                         success(res) {
                             res.confirm && uni.navigateTo({
                                 url: '/pages/index/bindPhone/bindPhone'
                             })
                         }
                    })
                	return [null, res.data]
                }
            } else {
				console.log(url, error)
                // handleError(error.statusCode, url)
                return [error, undefined]
            }
        } catch (error) {
            // uni.showToast({
            //     title: "系统繁忙，请稍后再试!",
            //     icon: 'none'
            // })
            return [error, undefined]
        }

    }
}

const errText = {
    403: "请先登录",
    401: "请先登录!",
    404: "请求的资源不存在!",
    500: "系统出现异常，请稍后再试!"
}
const toLogin = debounceEvent(function() {
	console.warn("token过期")
	uni.reLaunch({
		url: "/pages/login/login",
		fail: (err) => {
			console.log("跳换登录： ",err)
		}
	})
    uni.removeStorageSync('token')
    uni.removeStorageSync('userinfo')
	
	
}, 5000)
function handleError(errno, url) {
    // if (url === "/login/login") {
    //     uni.showToast({
    //         title: "系统繁忙，请稍后再试!",
    //         icon: 'none'
    //     })
    //     return;
    // }
    // if (errno in errText) {
    //     uni.showToast({
    //         title: errText[errno],
    //         icon: 'none'
    //     })
    // } else {
    //     uni.showToast({
    //         title: '系统繁忙，请稍后再试!',
    //         icon: 'none'
    //     })
    // }


    if (errno === 401) {
        // 先清除本地缓存
        clearStorage();
        // setTimeout(() => {
        //     uni.navigateTo({
        //         url: '/pages/login/index'
        //     })
        // }, 500)
    }
}
const request = new Request({
    baseURL: baseApiUrl,
    withBaseURL: true
})
export default request;