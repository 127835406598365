// let baseImgUrl = process.env.NODE_ENV === 'production' ? "https://gf.cleair-cn.com" : "https://https://gf.cleair-cn.com"
let ApiUrl = process.env.NODE_ENV == 'production' ? "https://admin.ejoyhome.com" : "https://admin.ejoyhome.com"
let appid1 = 'wxb32bfbd3aa5855a5'
let onLineUrl1 = "https://mobile.ejoyhome.com"
// #ifdef H5-UAT
	console.warn("注意当前是测试UAT环境！！！！！！不能直接发布到正式环境！")
	ApiUrl = 'https://cshoutai.ejoyhome.com'
	appid1 = 'wxb32bfbd3aa5855a5'
	onLineUrl1 = 'https://csnose.ejoyhome.com'
// #endif

export const onLineUrl = onLineUrl1
export const Appid = appid1
export const baseApiUrl = ApiUrl


// 企业微信id
export const companyWxAppid = 'ww3e48baf14bb06c59'
// 白天客服
export const baitianKF = 'https://work.weixin.qq.com/kfid/kfce21238790582427a'
// 夜晚客服
export const yewanKF = 'https://work.weixin.qq.com/kfid/kfc192adf0f3d76601c'


export const IosAppId = 1644111961
// 微信开放平台id
export const wxOpenAppid = 'wx820e5336e508985e'

// 客服电话
export const phoneNumber = "400-880-2575"
// if (process.env.NODE_ENV === 'development') {
//     // TODO
// }
// // uEnvProd
// if (process.env.NODE_ENV === 'production') {
//     // TODO
// }
console.log(process.env)
export const callPhone = () => {
    uni.makePhoneCall({
        phoneNumber
    });
}
export const updateText = '新版本已准备好,可随时更新...'