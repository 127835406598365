
import request from "./request.js"
import { baseApiUrl } from "./config.js"

// method默认post
const api = {
	
	//H5登录
	checkLogin: {
		url: "/api/user.Login/checkLogin",
		method: "post"
	},
	//app登录
	appLogin:{
		url:"/api/user.login/appLogin"
	},
	//绑定苹果id
	bindIos:{
		url:"/api/user.user/bindIos"
	},
	bindPhone:{
		url:"/api/user.user/bindPhone"
	},
	bindWechat:{
		url:"/api/user.user/bindWechat"
	},
	//苹果id登录
	IosLogin:{
		url:"/api/user.login/IosLogin"
	},
	phoneLogin: {
		url: "/api/user.login/phoneLogin"
	},
	//获取验证码
	obtain:{
		url:"/api/pub/obtain"
	},
	//用户信息
	userInfo: {
		url: "/api/user.user/info",
	},
	//上传图片
	uploadImg: {
		url: "/api/Osssave/upload"
	},
	//获取轮播图
	getBanner:{
		url:"/api/user.Index/banner"
	},
	//获取首页项目列表 or 获取该技师所有项目
	getGoodsList:{
		url:"/api/user.goods/lists"
	},
	//获取一级城市
	GetCityDis:{
		url:"/api/pub/GetCityDis"
	},
	// 代理员申请
	apply: {
		url: "/api/user.agent/apply",
	},
	//代理员申请详情
	agentInfo:{
		url:"/api/user.agent/info"
	},
	//技师申请
	masterApply:{
		url:"/api/user.Technician/apply"
	},
	//技师申请详情
	masterAgentInfo:{
		url:"/api/user.Technician/info"
	},
	// 技师列表
	getMasterList:{
		url:"/api/user.Technician/lists"
	},
	//收藏技师
	collectionMaster:{
		url:"/api/user.collect/handle"
	},
	//收藏技师列表页
	collectionMasterList:{
		url:"/api/user.collect/users"
	},
	//获取申请技师所有二级城市
	GetThreeCity:{
		url:"/api/pub/GetThreeCity"
	},
	//获取优惠券
	coupon:{
		url:"/api/user.coupon/lists"
	},
	//用户余额明细
	balanceList:{
		url:"/api/user.User/balanceList"
	},
	//微信支付配置
	payConfig:{
		url:"/api/user.Wechat/GetPayConfig"
	},
	//充值
	Recharge:{
		url:"/api/user.user/Recharge"
	},
	//提现
	Withdrawal:{
		url:"/api/user.user/Withdrawal"
	},
	//项目详情
	goodsInfo:{
		url:"/api/user.goods/info"
	},
	//技师详情
	masterInfo:{
		url:"/api/user.Technician/tInfo"
	},
	//评论列表
	CommentList:{
		url:"/api/user.Comment/CommentList"
	},
	//所有地址
	addressList:{
		url:"/api/user.Address/lists"
	},
	//添加或修改地址
	upAddress:{
		url:"/api/user.Address/upAddress"
	},
	//删除地址
	delAdd:{
		url:"/api/user.Address/del"
	},
	//设置默认地址
	setDefault:{
		url:"/api/user.Address/setDefault"
	},
	//提交订单前查询
	beforeAdd:{
		url:"/api/user.order/beforeAdd"
	},
	//订单距离 用于计算价钱
	orderDistance:{
		url:"/api/user.order/orderDistance"
	},
	//技师工作时间获取
	WorkTime:{
		url:"/api/user.order/WorkTime"
	},
	//支付订单
	orderAdd:{
		url:"/api/user.order/Add"
	},
	//再次支付
	againPay:{
		url:"/api/user.order/againPay"
	},
	//订单详情
	orderInfo:{
		url:"/api/user.order/info"
	},
	//更换服务
	replaceService:{
		url:"/api/user.order/replaceService"
	},
	//取消订单
	cancelOrder:{
		url:"/api/user.order/cancelOrder"
	},
	//订单列表
	orderLists:{
		url:"/api/user.order/lists"
	},
	//获取评论标签
	tagList:{
		url:"/api/user.comment/tagList"
	},
	//发表评论
	addComment:{
		url:"/api/user.Comment/add"
	},
	//问题反馈
	feedback:{
		url:"/api/user.index/feedback"
	},
	//定位搜索城市
	searchCity:{
		url:"/api/pub/searchCity"
	},
	//是否存在订单
	isExistOrder:{
		url:"/api/pub/isExistOrder"
	},
	//报警
	callPolice:{
		url:"/api/pub/callPolice"
	},
	//删除订单
	delOrder:{
		url:"/api/user.order/del"
	},
	//二级代理
	lowerLevel:{
		url:"/api/user.agent/lowerLevel"
	},
	//隐私政策和电话(碎片接口)
	fragmentInfo:{
		url:"/api/technician.personal/fragmentInfo"
	},
	//版本更新
	Version:{
		url:"/api/pub/Version"
	},
	//获取充值活动列表
	rechargeSetList:{
		url:"/api/user.user/RechargeSetList"
	},
	//退款
	RechargeRefund:{
		url:"/api/user.user/RechargeRefund"
	},
	//生成二维码
	createQrcode:{
		url:"/api/user.user/createQrcode"
	},
	//商户列表
	getPreferentialMerchantList:{
		url:"/api/user.preferential_merchant/lists"
	},
	//代理数据统计
	statistics:{
		url:"/api/user.agent/statistics"
	},
	// 投诉
	tousu:{
		url:"/api/user.complaint/add"
	},
	tousuTagList:{
		url:"/api/user.complaint/tagList"
	},
	cate_lists:{
		url:"/api/user.goods/cate_lists"
	},
    NextClockReview: {
        url: '/api/user.order/NextClockReview'
    }
}
export const uploadImg = `${baseApiUrl}${api.uploadImg.url}` // 上传图片完整地址
export const login = async (data) => {
	return request.get(api.login.url, data)
}
export const getUser = async (data) => {
	return request.get(api.getUser.url, data)
}
const ApiGloabal = {}
for (const key in api) {
	const _obj = api[key]
	ApiGloabal[key] = async (data, config) => {
		try {
			if (String(_obj.method).toUpperCase() === 'GET') {
				return request.get(api[key].url, data,key)
			} else {
				return request.post(api[key].url, data,key)
			}
		} catch (error) {
			console.error(error)
		}
		
	}
}
export default ApiGloabal