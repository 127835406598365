import $api from "../utils/api.js"
import { createStore } from "vuex";
import {
	getSessionKey,
	getTokenStorage,
	setStorage,
	getStorage,
	setUserInfoStorage,
	getUserInfoStorage
} from "@/service/storage"
import defult, {
	login,
	getUser
} from "../utils/api.js"
// Vue.use(Vuex)
let globalPromise = null
const user = getUserInfoStorage() || {}
const longitude = getStorage('longitude') || ""
const latitude = getStorage('latitude') || ""
const myCityInfo = getStorage('myCityInfo') || {
    id: 6,
    name: '贵阳市'
}
const store = createStore({
	state: {
		hasLogin: false,
		openid: '',
		isOnlineServices: 0,
		userInfo: user || {},
		userId: "",
		StatusBar: 0,
		longitude: longitude || "", //经度
		latitude: latitude || "", //纬度
		isCallShow: false, //报警是否显示
		orderIdList: [], //可举报的进行中的订单
		isUpdateText:false,//是否有船新版本
		downloadApkUrl:'',//安装包地址
		contentText:"",//版本更新内容
		versionNum:"",//当前版本号
		myCityInfo: myCityInfo, //我所在的城市
		cityList: [], // 城市劣币哦啊
	},
	getters: {
		openid: state => state.openid,
		userInfo: state => state.userInfo,
		isOnlineServices: state => state.isOnlineServices,
		userId: state => state.userId,
		myCityInfo: state => state.myCityInfo,
		isCallShow: state => state.isCallShow,
		orderIdList: state => state.orderIdList
	},
	mutations: {
		login(state) {
			state.hasLogin = true;
		},
		logout(state) {
			state.hasLogin = false;
		},
		setOpenId(state, openid) {
			state.openid = openid
		},
		setUserInfo(state, userInfo) {
			state.userInfo = userInfo
		},
		setUid(state, userId) {
			state.userId = userId
		},
		setToken(state, token) {
			state.token = token
		},
		setIsCallShow(state, isCallShow) {
			state.isCallShow = isCallShow
		},
		setorderIdList(state, orderIdList) {
			state.orderIdList = orderIdList
		},
		clearInfo(state) {
			state.hasLogin = false
			state.openid = ''
			state.isOnlineServices = 0
			state.userId = ''
			state.userInfo = {}
		},
		setDownloadApkUrl(state,downloadApkUrl){
			state.downloadApkUrl = downloadApkUrl
		},
		setVersionNum(state,versionNum){
			state.versionNum = versionNum
		},
		setContent(state,contentText){
			state.contentText = contentText
		},
		setIsUpdateText(state,isUpdateText){
			state.isUpdateText = isUpdateText
		},
		setCityInfo(state, obj){
			state.myCityInfo = obj
			setStorage('myCityInfo',obj)
		},
		setCityList (state, arr) {
			store.cityList = arr
		}
	},
	actions: {
		async login(context, query = false) {
			let reFresh = false
			let openid = ''
			if (typeof query == 'boolean') {
				reFresh = query
			} else {
				reFresh = query.isrefresh
				openid = query.openid
			}
			if (reFresh) {
				globalPromise = null
			}
			if (globalPromise !== null) {
				console.log('vuex login has GlobalPromise')
				return globalPromise
			}
			console.log('vuex login start', openid)
			const fn = async () => {
				try {
					uni.showLoading({
						title: 'Loading'
					})
					console.log('vuex login GlobalPromise')
					const [error, res] = await uni.login({
						provider: 'weixin'
					});
					if (error) {
						uni.hideLoading()
						return;
					}
					console.log('login code:', res.code)
					const [err, res1] = await login({
						code: res.code,
						openid_x: openid
					})
					uni.hideLoading()
					if (err) {
						return
					}
					context.commit('login')
					context.commit('setOpenId', res1.data.openid)
					context.commit('setUserInfo', res1.data.userinfo)

					setUserInfoStorage(res1.data.userinfo)
					return res1.data.userinfo

				} catch (e) {
					uni.hideLoading()
					globalPromise = null
					return e
				}
			}
			globalPromise = fn()
			// 接口请求报错过后重置globalPromise 下次继续发送正常请求
			globalPromise.catch(() => {
				globalPromise = null
			})
			return globalPromise
		},

		async getUser(context) {
			// 获取用户信息
			console.log("获取用户信息")
			const [err1, user] = await $api.userInfo({
				user_id: context.state.userInfo.id,
				// #ifdef APP-PLUS
				type: 'app',
				//#endif
				//#ifdef H5
				type: 'wechat',
				//#endif
			})
			if (err1 || (user && user.code !== 10000)) {
				return console.log(user)
			}
			if (user.data.balance == '0.00') {
				user.data.balance = 0
			}
			setUserInfoStorage(user.data)
			context.commit('setUserInfo', user.data)
			context.commit('setOpenId', user.data.openid || "")
			return user.data
		},
		//是否存在订单
		async isExistOrder(context) {
			const [err, res] = await $api.isExistOrder({
				id: context.state.userInfo.id,
				type: 0
			})
			if (res.code == 10000) {
				context.commit('setIsCallShow', true)
				context.commit('setorderIdList', res.data)
				console.log("存在订单", res)
			} else {
				context.commit('setIsCallShow', false)
			}
		},
		//一键报警
		async callPolice(context) {
			uni.showLoading({
				// title:"加载中"
			})
			const [err, res] = await $api.callPolice({
				id: context.state.userInfo.id,
				type: 0,
				order_id: context.state.orderIdList
			})
			uni.hideLoading()
			if (res.code != 10000) {
				console.log(res.msg || '报警失败')
				uni.showToast({
					title: res.msg,
					icon: 'none'
				})
			} else {
				uni.showToast({
					title: "报警成功",
					// icon
				})
			}
			// console.log(res)
		},
		// async getUser(context) {
		// 	// 获取用户信息
		// 	const [err1, user] = await getUser({
		// 		openid: context.state.openid,
		// 	})
		// 	if (err1 || (user && user.code !== 200)) {
		// 		return false
		// 	}
		// 	setUserInfoStorage(user.data)
		// 	context.commit('setUserInfo', user.data)
		// 	return user.data
		// },
		//获取所有城市
		async GetThreeCity(context) {
			// const myCityInfo = uni.getStorageSync('myCityInfo')
			// if(myCityInfo) return
			const [err, res] = await $api.GetThreeCity()
			if (res.code!=10000) {
				return this.$toast('获取城市异常')
			}
			context.commit('setCityList', res.data || [])
			//原始数组
		},
	}
})

export default store
